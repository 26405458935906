<template>
    <div class="app-container">
        <div
            class="filter-container"
            :style="{
                visibility: raw_categories.length > 0 ? 'visible' : 'hidden',
            }"
        >
            <div class="budget-selector-row">
                <span>Selected budgets:</span>
                <el-select
                    v-model="selectedBudgets"
                    class="budget-selector"
                    placeholder="All budgets"
                    clearable
                    multiple
                >
                    <el-option
                        v-for="category in raw_categories"
                        :key="category.id"
                        :value="category.id"
                        :label="category.name"
                    />
                </el-select>
            </div>

            <div class="user-options-row">
                <el-switch v-model="filterContacted" />
                <span :class="{disabled: !filterContacted}">
                    Only users <i>not</i> contacted about
                    <el-select
                        v-model="filterContactedTypes"
                        class="user-options-types"
                        :disabled="!filterContacted"
                        placeholder="anything"
                        multiple
                        clearable
                    >
                        <el-option label="Core" value="core" />
                        <el-option label="IDL" value="idl" />
                    </el-select>
                    since
                    <el-date-picker
                        v-model="filterContactedDate"
                        :disabled="!filterContacted"
                        class="user-options-date"
                        placeholder="ever"
                    />
                </span>
            </div>

            <div class="budget-options-row">
                <el-button
                    :disabled="!users || users.length == 0"
                    @click="exportCSV"
                >
                    Export CSV
                </el-button>
                <span class="spacer" />
                <el-switch v-model="includeMissing" />
                <span :class="{disabled: !includeMissing}">
                    Include users without these budgets
                </span>

                <el-switch v-model="isBudgetLimited" />

                <span :class="{disabled: !isBudgetLimited}">
                    Limit to budget totals below
                </span>
                <money
                    v-model="budget_limit"
                    v-bind="money"
                    :disabled="!isBudgetLimited"
                    class="el-input__inner budget-limit"
                    :class="{disabled: !isBudgetLimited}"
                />

                <el-button
                    type="primary"
                    :disabled="loading.state"
                    @click="getLowBudgets"
                >
                    Search
                </el-button>
            </div>
        </div>

        <user-list
            v-loading="loading.state"
            :users="users"
            :element-loading-text="loading.message"
            :css="{height: 'calc(100vh - 320px)'}"
            :show_last_contacted="true"
            :selectable="true"
            :scroll="true"
            :new_window="true"
            @new-claim="newClaim"
            @select="handleSelectUsers"
            @contact="handleContactForUser"
        />

        <div
            class="selection-actions"
            :class="{disabled: !selectedUsers.length}"
        >
            <el-button
                icon="el-icon-message"
                :disabled="!selectedUsers.length"
                @click="handleContactForSelectedUsers('core')"
                >Core</el-button
            >
            <el-button
                icon="el-icon-message"
                :disabled="!selectedUsers.length"
                @click="handleContactForSelectedUsers('idl')"
                >IDL</el-button
            >
        </div>

        <claim-modal
            :categories="user_categories"
            :data="user_plan"
            :user="user"
        />
    </div>
</template>

<script>
import UserList from '@/views/components/UserList';
import ClaimModal from '@/views/components/ClaimModal';
import moment from 'moment';
import _ from 'lodash';
import {Money} from 'v-money';
import firebase from 'firebase/app';

export default {
    name: 'expiry',
    components: {
        UserList,
        ClaimModal,
        Money,
    },
    props: {
        view: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            users: [],
            userQueue: null, // users pending processing
            loading: {
                state: false,
                message: null,
            },
            loadingMessage: null,
            selectedBudgets: ['3', '1', '4'], // Consumables, Daily Activities, Social & Community
            isBudgetLimited: true,
            budget_limit: 1000,
            budget_count: 0, // progress counter for loading budgets
            user_count: 0, // progress counter for loading users

            includeMissing: false,

            // new claims
            user_categories: [],
            user_plan: [],
            user: {},
            raw_categories: [],
            money: {
                decimal: '.',
                thousands: ',',
                prefix: '$',
                precision: 2,
                masked: false,
            },
            selectedUsers: [],

            filterContacted: false,
            filterContactedTypes: [],
            filterContactedDate: null,
        };
    },

    computed: {
        userList() {
            return this.users || [];
        },
    },

    async mounted() {
        this.$bind(
            'raw_categories',
            this.$fire.collection('categories').orderBy('order'),
            {maxRefDepth: 0}
        );
    },

    methods: {
        async getLowBudgets() {
            this.loading.state = true;
            this.loading.message = 'Finding active plans';

            // get all active plans
            let query = this.$fire.collection('plans');

            const now = moment().startOf('day').subtract(1, 'minute').toDate(); // subtract 1 min to catch expiry dates for today
            query = query.where('expiry', '>', now);

            let plans = [];

            await query.get().then((snapshot) => {
                snapshot.docs.forEach((doc) => {
                    plans.push({...doc.data(), id: doc.id, ref: doc.ref});
                });
            });

            this.loading.message = `${plans.length} active plans found`;

            this.users = [];
            this.userQueue = [];
            let budgetRequests = [];
            let userRequests = [];

            // get budgets for active plans
            this.budget_count = 0;
            for (let i = 0; i < plans.length; i++) {
                budgetRequests.push(this.getBudgetsForPlan(plans[i]));
            }
            await Promise.all(budgetRequests);

            // don't include this budget if no budgetTotal was set, or if budgetTotal is less than threshold
            plans = plans.filter((plan) => {
                return (
                    plan.budgetTotal !== false &&
                    (!this.isBudgetLimited ||
                        plan.budgetTotal <= this.budget_limit)
                );
            });

            // get users for matching plans
            this.user_count = 0;
            this.loading.message = `${plans.length} matching plans, loading user data`;
            for (let i = 0; i < plans.length; i++) {
                userRequests.push(this.getUserByPlanRef(plans[i]));
            }
            await Promise.all(userRequests);

            // for the purposes of this list, only the plan found by initial query is relevant
            this.userQueue.map((user) => {
                user.plans = [user.activePlan];
            });

            this.userQueue.sort((a, b) => {
                let order = a.activePlan.budgetTotal - b.activePlan.budgetTotal;
                if (order == 0) {
                    // sort alphabetically where budgets are the same
                    order = a.name.localeCompare(b.name);
                }
                return order;
            });

            this.users = this.userQueue;

            this.loading.state = false;
        },

        async getBudgetsForPlan(plan) {
            let budgetTotal = 0;
            let matchFound = false;
            // get all selected budgets

            if (plan.data) {
                for (const item of plan.data) {
                    if (
                        this.selectedBudgets.length == 0 ||
                        this.selectedBudgets.includes(item.category.id)
                    ) {
                        matchFound = true;
                        const snapshot = await item.budget.get();
                        const budget = snapshot.data();

                        // update loading message as we progress
                        this.budget_count++;
                        const b = this.budget_count;
                        this.loading.message = `Loading budgets (${b})`;

                        budgetTotal +=
                            budget.budget_allocated - budget.budget_spent;
                    }
                }
            }
            // append budget total to plan if matching budgets were found
            if (matchFound || this.includeMissing) {
                plan.budgetTotal = budgetTotal;
            } else {
                plan.budgetTotal = false;
            }
        },

        async getUserByPlanRef(plan) {
            // get all users, filtered by status & search
            let query = this.$fire
                .collection('users')
                .where('status', '==', 'active')
                .where('plans', 'array-contains', plan.ref);

            let user = null;

            await query.get().then((snapshot) => {
                for (let i = 0; i < snapshot.size; i++) {
                    let doc = snapshot.docs[i];
                    user = {...doc.data(), id: doc.id};
                    user.activePlan = plan;
                    if (!user.contacted) {
                        user.contacted = [];
                    }
                }
            });

            if (user && this.filterContacted) {
                // limit to users matching filter

                // treat empty type filter as all types
                const types = this.filterContactedTypes.length
                    ? this.filterContactedTypes
                    : ['core', 'idl'];
                const date = this.filterContactedDate
                    ? moment(this.filterContactedDate)
                    : null;

                const userFound = user.contacted.find((contact) => {
                    if (date) {
                        return (
                            types.includes(contact.type) &&
                            moment(contact.date.toDate()).isSameOrAfter(date)
                        );
                    } else {
                        return types.includes(contact.type);
                    }
                });

                if (userFound) {
                    // skip this user
                    user = null;
                }
            }

            if (user) {
                this.user_count++;
                const u = this.user_count;
                this.loading.message = `Loading users (${u})`;

                this.userQueue = _.unionBy(this.userQueue, [user], 'id');
            }
        },

        newClaim(user, activePlan) {
            this.user = user;
            this.user_plan = activePlan.data.map((item) => {
                return {category: item.category.path, budget: item.budget.path};
            });

            // get assigned categories for user
            const plan_categories = activePlan.data.map((item) => {
                return item.category.path;
            });
            this.user_categories = this.raw_categories
                .filter((raw_cat) => {
                    return plan_categories.includes(`categories/${raw_cat.id}`);
                })
                .sort((a, b) => {
                    return a.order - b.order;
                });
            this.$bus.$emit('claim-modal', {user});
        },

        handleSelectUsers(users) {
            this.selectedUsers = users;
        },

        handleContactForUser(user, type) {
            this.addUserContactEvent(user, type);
        },

        handleContactForSelectedUsers(type) {
            this.selectedUsers.forEach((user) => {
                this.addUserContactEvent(user, type);
            });
        },

        addUserContactEvent(userId, type) {
            const user = this.users.find((u) => u.id === userId);

            // only add a new entry if there isn't already a matching contact event today
            const lastContact = user.contacted.find((c) => c.type === type);
            if (
                lastContact &&
                moment(lastContact.date.toDate()).isSame(moment(), 'day')
            ) {
                return;
            }

            if (user) {
                // update firestore record
                this.$fire.doc(`users/${userId}`).update({
                    contacted: firebase.firestore.FieldValue.arrayUnion({
                        plan: user.activePlan.ref,
                        type,
                        date: moment().startOf('day').toDate(),
                    }),
                });

                // update local record (to save a requery)
                user.contacted = [
                    {
                        plan: user.activePlan.ref,
                        type,
                        date: moment(),
                    },
                    ...user.contacted,
                ];
            }
        },

        exportCSV() {
            let csv = 'Name,Code';
            this.users.forEach((user, index) => {
                // replace any double-quotes with 2 double-quotes to escape them
                let name = user.name.replace('"', '""');
                let code = user.code.replace('"', '""');
                csv += `\n"${name}","${code}"`;
            });

            const exportDate = moment().format('YYYYMMDD');

            var element = document.createElement('a');
            element.setAttribute(
                'href',
                'data:text/plain;charset=utf-8,' + encodeURIComponent(csv)
            );
            element.setAttribute('download', `lowbudgets_${exportDate}.csv`);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.pagination-container {
    background-color: white;
    margin: 0;
    padding: 20px;
    border: 1px solid #ebeef5;
    border-top: 0;
    display: flex;
    justify-content: center;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.filter-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > div {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        > *:not(:last-child) {
            margin-right: 10px;
        }
        &.user-options-row {
            justify-content: flex-end;
            margin-top: 10px;
            .user-options-types {
                width: 160px;
            }
            .user-options-date {
                width: 150px;
                ::v-deep input {
                    padding-left: 30px !important;
                }
            }
        }
        &.budget-options-row {
            margin-top: 10px;
            .el-switch {
                margin-left: 20px;
            }
            .spacer {
                flex: 1;
            }
        }
    }

    .budget-selector {
        flex: 1;
    }
    .budget-limit {
        flex: 0;
        width: 150px;
    }

    .disabled {
        color: #afafaf;
    }
}

.full-width {
    width: 100%;
}

.el-tag {
    width: 100%;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 13px;
    font-weight: 500;
}

.selection-actions {
    text-align: right;
    span {
        font-size: 14px;
        margin-right: 10px;
    }
    &.disabled {
        span {
            opacity: 0.3;
        }
    }
}
</style>
