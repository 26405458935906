var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        {
          staticClass: "filter-container",
          style: {
            visibility: _vm.raw_categories.length > 0 ? "visible" : "hidden",
          },
        },
        [
          _c(
            "div",
            { staticClass: "budget-selector-row" },
            [
              _c("span", [_vm._v("Selected budgets:")]),
              _c(
                "el-select",
                {
                  staticClass: "budget-selector",
                  attrs: {
                    placeholder: "All budgets",
                    clearable: "",
                    multiple: "",
                  },
                  model: {
                    value: _vm.selectedBudgets,
                    callback: function ($$v) {
                      _vm.selectedBudgets = $$v
                    },
                    expression: "selectedBudgets",
                  },
                },
                _vm._l(_vm.raw_categories, function (category) {
                  return _c("el-option", {
                    key: category.id,
                    attrs: { value: category.id, label: category.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "user-options-row" },
            [
              _c("el-switch", {
                model: {
                  value: _vm.filterContacted,
                  callback: function ($$v) {
                    _vm.filterContacted = $$v
                  },
                  expression: "filterContacted",
                },
              }),
              _c(
                "span",
                { class: { disabled: !_vm.filterContacted } },
                [
                  _vm._v(" Only users "),
                  _c("i", [_vm._v("not")]),
                  _vm._v(" contacted about "),
                  _c(
                    "el-select",
                    {
                      staticClass: "user-options-types",
                      attrs: {
                        disabled: !_vm.filterContacted,
                        placeholder: "anything",
                        multiple: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.filterContactedTypes,
                        callback: function ($$v) {
                          _vm.filterContactedTypes = $$v
                        },
                        expression: "filterContactedTypes",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "Core", value: "core" },
                      }),
                      _c("el-option", {
                        attrs: { label: "IDL", value: "idl" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" since "),
                  _c("el-date-picker", {
                    staticClass: "user-options-date",
                    attrs: {
                      disabled: !_vm.filterContacted,
                      placeholder: "ever",
                    },
                    model: {
                      value: _vm.filterContactedDate,
                      callback: function ($$v) {
                        _vm.filterContactedDate = $$v
                      },
                      expression: "filterContactedDate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "budget-options-row" },
            [
              _c(
                "el-button",
                {
                  attrs: { disabled: !_vm.users || _vm.users.length == 0 },
                  on: { click: _vm.exportCSV },
                },
                [_vm._v(" Export CSV ")]
              ),
              _c("span", { staticClass: "spacer" }),
              _c("el-switch", {
                model: {
                  value: _vm.includeMissing,
                  callback: function ($$v) {
                    _vm.includeMissing = $$v
                  },
                  expression: "includeMissing",
                },
              }),
              _c("span", { class: { disabled: !_vm.includeMissing } }, [
                _vm._v(" Include users without these budgets "),
              ]),
              _c("el-switch", {
                model: {
                  value: _vm.isBudgetLimited,
                  callback: function ($$v) {
                    _vm.isBudgetLimited = $$v
                  },
                  expression: "isBudgetLimited",
                },
              }),
              _c("span", { class: { disabled: !_vm.isBudgetLimited } }, [
                _vm._v(" Limit to budget totals below "),
              ]),
              _c(
                "money",
                _vm._b(
                  {
                    staticClass: "el-input__inner budget-limit",
                    class: { disabled: !_vm.isBudgetLimited },
                    attrs: { disabled: !_vm.isBudgetLimited },
                    model: {
                      value: _vm.budget_limit,
                      callback: function ($$v) {
                        _vm.budget_limit = $$v
                      },
                      expression: "budget_limit",
                    },
                  },
                  "money",
                  _vm.money,
                  false
                )
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.loading.state },
                  on: { click: _vm.getLowBudgets },
                },
                [_vm._v(" Search ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("user-list", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading.state,
            expression: "loading.state",
          },
        ],
        attrs: {
          users: _vm.users,
          "element-loading-text": _vm.loading.message,
          css: { height: "calc(100vh - 320px)" },
          show_last_contacted: true,
          selectable: true,
          scroll: true,
          new_window: true,
        },
        on: {
          "new-claim": _vm.newClaim,
          select: _vm.handleSelectUsers,
          contact: _vm.handleContactForUser,
        },
      }),
      _c(
        "div",
        {
          staticClass: "selection-actions",
          class: { disabled: !_vm.selectedUsers.length },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-message",
                disabled: !_vm.selectedUsers.length,
              },
              on: {
                click: function ($event) {
                  return _vm.handleContactForSelectedUsers("core")
                },
              },
            },
            [_vm._v("Core")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-message",
                disabled: !_vm.selectedUsers.length,
              },
              on: {
                click: function ($event) {
                  return _vm.handleContactForSelectedUsers("idl")
                },
              },
            },
            [_vm._v("IDL")]
          ),
        ],
        1
      ),
      _c("claim-modal", {
        attrs: {
          categories: _vm.user_categories,
          data: _vm.user_plan,
          user: _vm.user,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }